<script lang="ts">
	import { DeprecatedFetch_useapiFetch } from '$lib/utils/fetch';
	import { Loader2 } from 'lucide-svelte';

	import { cn } from '$lib/utils/utils';
	import { browser } from '$app/environment';

	import Text from '$lib/components/input/text.svelte';
	import { goto } from '$app/navigation';
	import LoginButton from './LoginButton.svelte';
	import Checkbox from '$lib/components/ui/Checkbox.svelte';
	import Card from './Card.svelte';
	import Button from '../ui/Button.svelte';
	import { page } from '$app/stores';
	import { closeModal, openModal } from 'svelte-modals';
	import Modal from '$lib/components/Modal.svelte';
	import Login from './Login.svelte';
	import { user } from '$lib/stores/user';

	let username: string;
	let password: string;
	let email: string;
	let social: string;
	let number: string;

	let login_password: string;
	let login_username: string;

	let user_agreement: boolean;
	let playpark_stadgar: boolean;
	let sverok_agreement: boolean;

	let modalStyle: string;
	let isLoading: boolean;
	let defaultStyle = 'p-2 border rounded-md border-zinc-800 w-full flex justify-center';

	let registerMessage: string;

	async function openLogin() {
		if ($page.url.pathname.includes('register')) {
			goto('/login');
		} else {
			closeModal();
			openModal(Modal, {
				component: Login,
				props: null
			});
		}
	}

	const handleRegister = async () => {
		registerMessage = '';
		isLoading = true;

		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/register',
			method: 'POST',
			body: {
				username: username,
				password: password,
				email: email,
				personnummer: social,
				telefonnummer: number,
				user_agreement,
				playpark_stadgar,
				sverok_agreement
			}
		})
			.then((data) => {
				registerMessage = 'Registrerad!';
				modalStyle = 'border-green-500 bg-green-500/30';
				isLoading = false;
				login_username = username;
				login_password = password;
				setTimeout(() => {
					loginAfterRegister();
				}, 6000);
			})
			.catch((err) => {
				registerMessage = 'Något gick fel med registreringen, försök igen senare';
				modalStyle = 'border-red-500 bg-red-500/30';
				isLoading = false;
			});

		return;
	};

	const loginAfterRegister = async () => {
		isLoading = true;
		modalStyle = 'border-orange-500 bg-orange-500/30';
		registerMessage = 'Loggar in...';
		await DeprecatedFetch_useapiFetch({
			endpoint: '/auth/login',
			method: 'POST',
			body: { username: login_username, password: login_password }
		})
			.then((data) => {
				isLoading = false;

				modalStyle = 'border-green-500 bg-green-500/30';
				user.set(JSON.parse(JSON.stringify(data.data)));
				setTimeout(() => {
					goto('/');
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
				isLoading = false;
				registerMessage = 'Något gick fel med att logga in, försök igen senare';
				modalStyle = 'border-red-500 bg-red-500/30';
			});
	};

	import { createForm } from 'felte';

	import { reporter } from '@felte/reporter-svelte';
	import { validator } from '@felte/validator-zod';
	import * as z from 'zod';
	import FormText from '../input/FormText.svelte';
	import FormCheckbox from '../input/FormCheckbox.svelte';

	function validateSwedishSSNChecksum(ssn: string): boolean {
		const digits = ssn.replace(/\D/g, '');
		let sum = 0;
		for (let i = 0; i < 9; i++) {
			let digit = parseInt(digits[i]);
			if (i % 2 === 0) {
				digit *= 2;
				if (digit > 9) {
					digit -= 9;
				}
			}
			sum += digit;
		}
		const checksum = (10 - (sum % 10)) % 10;
		return checksum === parseInt(digits[9]);
	}

	let submitted: z.infer<typeof schema> | undefined;

	const schema = z.object({
		username: z.string().min(2, {
			message: 'Användarnamnet måste vara minst 2 tecken långt'
		}),
		email: z.string().email({
			message: 'E-postadressen måste vara giltig'
		}),
		password: z.string().min(8, {
			message: 'Lösenordet måste vara minst 8 tecken långt'
		}),
		social: z
			.string()
			.min(12, {
				message: 'Personnumret måste vara minst 12 tecken långt'
			})
			.max(12, { message: 'Personnumret får max vara 12 tecken långt' })
			.refine(
				(value) => {
					// Basic format check (YYYYMMDD-XXXX or YYMMDD-XXXX)
					const regex = /^(\d{6}|\d{8})[-+]?\d{4}$/;
					if (!regex.test(value)) {
						return false;
					}

					// Extract date parts
					const parts = value.replace(/\D/g, '');
					const year = parts.length === 12 ? parts.slice(0, 4) : `19${parts.slice(0, 2)}`;
					const month = parts.slice(-8, -6);
					const day = parts.slice(-6, -4);

					// Validate date
					const date = new Date(`${year}-${month}-${day}`);
					if (isNaN(date.getTime())) {
						return false;
					}

					// Validate checksum
					return validateSwedishSSNChecksum(parts.slice(-10));
				},
				{
					message: 'Ogiltigt personnummer'
				}
			),
		number: z
			.string()
			.min(10, {
				message: 'Telefonnumret måste vara minst 10 tecken långt'
			})
			.max(10, { message: 'Telefonnumret får max vara 10 tecken långt' }),
		user_agreement: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna användaravtalet' };
				}
				return { message: ctx.defaultError };
			}
		}),
		playpark_stadgar: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna Playparks stadgar' };
				}
				return { message: ctx.defaultError };
			}
		}),
		sverok_agreement: z.literal(true, {
			errorMap: (err, ctx) => {
				switch (err.code) {
					case 'invalid_literal':
						return { message: 'Du måste godkänna Sveroks hantering av personuppgifter' };
				}
				return { message: ctx.defaultError };
			}
		})
	});

	const { form, errors, data } = createForm<z.infer<typeof schema>>({
		onError(errors) {
			console.log(errors);
		},
		onSubmit(values) {
			console.log(values);
			submitted = values;
		},
		extend: [validator({ schema }), reporter]
	});

	let username_error: boolean = false;
	let email_error: boolean = false;
	let password_error: boolean = false;
	let social_error: boolean = false;
	let number_error: boolean = false;
	let user_agreement_error: boolean = false;
	let playpark_stadgar_error: boolean = false;
	let sverok_agreement_error: boolean = false;

	$: if ($errors?.username) {
		username_error = true;
	} else {
		username_error = false;
	}
	$: if ($errors?.email) {
		email_error = true;
	} else {
		email_error = false;
	}
	$: if ($errors?.password) {
		password_error = true;
	} else {
		password_error = false;
	}
	$: if ($errors?.social) {
		social_error = true;
	} else {
		social_error = false;
	}
	$: if ($errors?.number) {
		number_error = true;
	} else {
		number_error = false;
	}
	$: if ($errors?.user_agreement) {
		user_agreement_error = true;
	} else {
		user_agreement_error = false;
	}
	$: if ($errors?.playpark_stadgar) {
		playpark_stadgar_error = true;
	} else {
		playpark_stadgar_error = false;
	}
	$: if ($errors?.sverok_agreement) {
		sverok_agreement_error = true;
	} else {
		sverok_agreement_error = false;
	}
</script>

<Card>
	<div>
		<h1 class="text-3xl font-semibold text-left text-white">Bli medlem</h1>
		<span class="flex items-center py-1 space-x-2">
			<h4 class="text-sm xl:text-base text-zinc-400">Redan medlem?</h4>
			<Button functionToRun={openLogin}>Logga in</Button>
		</span>
	</div>

	<div class="flex flex-col items-center justify-center space-y-5">
		{#if registerMessage}
			<p class={cn(defaultStyle, modalStyle)}>{registerMessage}</p>
		{/if}
		<form class="flex flex-col w-full space-y-3" use:form>
			<!-- <Text
				bind:value={username}
				label={'Användarnamn'}
				label_for={'username'}
				required={true}
				focus={true}
			/>
			<Text bind:value={email} label={'E-post'} label_for={'email'} required={true} />
			<Text
				bind:value={password}
				label={'Lösenord'}
				label_for={'password'}
				required={true}
				type="password"
			/>
			<Text bind:value={social} label={'Personnummer'} label_for={'social'} required={true} />
			<Text bind:value={number} label={'Telefonnummer'} label_for={'number'} required={true} /> -->
			<FormText name="username" label="Användarnamn" error={username_error} />
			<FormText name="email" label="E-post" error={email_error} />
			<FormText name="password" label="Lösenord" error={password_error} type="password" />
			<FormText name="social" label="Personnummer" error={social_error} />
			<FormText name="number" label="Telefonnummer" error={number_error} />
			<!-- Accept all agreements -->
			<div class="flex flex-col gap-y-3">
				<span>
					<!-- {JSON.stringify($data)} -->
					<FormCheckbox
						label_for="user_agreement"
						name="user_agreement"
						bind:error={user_agreement_error}
						bind:checked={$data.user_agreement}
					>
						Jag godkänner <a href="/terms" class="text-blue-400 underline">användaravtalet</a>
					</FormCheckbox>
				</span>
				<span>
					<FormCheckbox
						label_for="playpark_stadgar"
						name="playpark_stadgar"
						bind:error={playpark_stadgar_error}
						bind:checked={$data.playpark_stadgar}
					>
						Jag godkänner <a href="/stadgar" class="text-blue-400 underline">Playparks stadgar</a> och
						att jag blir medlem i Playpark spelförening
					</FormCheckbox>
				</span>
				<span>
					<FormCheckbox
						label_for="sverok_agreement"
						name="sverok_agreement"
						bind:error={sverok_agreement_error}
						bind:checked={$data.sverok_agreement}
					>
						Jag godkänner hur <a href="/sverok" class="text-blue-400 underline"
							>Sverok hanterar mina personuppgifter</a
						>
					</FormCheckbox>
				</span>
			</div>
			<div class="h-full">
				<LoginButton state={isLoading ? 'loading' : 'default'} type="submit">
					<div class="relative flex items-center justify-center w-full px-4">Registrera</div>
				</LoginButton>
			</div>
		</form>
	</div>
</Card>

<style>
</style>
