<script lang="ts">
	import { ValidationMessage } from '@felte/reporter-svelte';
	import Checkbox from '$lib/components/ui/Checkbox.svelte';
	import { slide } from 'svelte/transition';
	export let name: string;
	export let label_for: string;
	export let error = false;
	export let checked: boolean = false;
</script>

<div class="input-container">
	<Checkbox required id={label_for} {name} bind:checked bind:error>
		<label for={label_for} class="text-sm text-white">
			<slot />
		</label>
	</Checkbox>
	<ValidationMessage for={name} let:messages>
		{#if messages && messages.length > 0}
			<div class="validation-message" transition:slide={{ duration: 300, axis: 'y' }}>
				<ul aria-live="polite" class="validation-message-ul">
					{#each messages as message}
						<li>{message}</li>
					{/each}
				</ul>
			</div>
		{/if}
	</ValidationMessage>
</div>

<style>
	.input-container {
		/* position: relative; */
		width: 100%;
	}

	.validation-message {
		display: block;
		width: 100%;
		padding: 0.3rem;
		/* box-sizing: border-box; */
		margin-top: -6px;
		z-index: 0;
	}

	.validation-message-ul {
		margin: 0;
		padding: 0;
		padding-top: 0.25rem;
		list-style: none;
		color: #f07575;
	}

	.validation-message-ul li {
		margin-bottom: 0.25rem;
	}

	.validation-message-ul li:last-child {
		margin-bottom: 0;
	}
</style>
